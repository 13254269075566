import ProgressButton from "@components/admin/ProgressButton";
import DocumentationPage from "@components/DocumentationPage";
import { delay } from "@utils/miscellaneous";

export default () => <DocumentationPage>

    <ProgressButton 
        variant="contained"
        color="primary"
        progressVariant="determinate"
        total={10} 
        onClick={async (incrementProgress) => {
            for(let i=0;i<10;i++) {
                await delay(1000);
                incrementProgress();
            }
        }}
    >
        Determinate (click me)
    </ProgressButton>

    <ProgressButton 
        color="primary"
        variant="contained"
        progressVariant="indeterminate"
        onClick={async () => {
            await delay(3000);
        }}
    >
        Indeterminate (click me)
    </ProgressButton>
    
</DocumentationPage>